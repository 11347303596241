import axios from 'axios';

const axiosProd = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_PROD}/v1`
});

const axiosDev = axios.create({
    // baseURL: `${process.env.REACT_APP_API_URL_DEV}/v1`
    baseURL: `${process.env.REACT_APP_API_URL_TEMP}/v1`
});


export { axiosProd, axiosDev };